import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  Tag,
  Input,
  Row,
  Col,
  Typography,
  Card,
  Space,
  Checkbox
} from "antd";
import { PlusOutlined, CloseCircleFilled } from "@ant-design/icons";
import axios from "../api/axios";
import useAuth from "./logincomponents/hooks/useAuth";
import { useNavigate } from "react-router-dom";
const { TextArea } = Input;
const { Title } = Typography;
const CheckboxGroup = Checkbox.Group;
const Topic = ({ onTopicChange, topic, topicCheck }) => {
  function handleChange(value) {
    onTopicChange(value);
  }
  return (
    <>
      <Input
        style={{ width: "40%", borderColor: topicCheck && topic === "" ? "red" : "" }}
        type="text"
        value={topic}
        name={topic}
        placeholder="Enter question topic"
        onChange={(e) => handleChange(e.target.value)}
        className="in-input"
      />
      {topicCheck && topic === "" && <div style={{ color: "#dc3545",fontWeight:"500" }}>
        Please choose a topic.
      </div>}
    </>
  );
};
const QuesionMode = ({
  onAddQuestion,
  addCategory,
  categoryNquestions,
  uncheckedCategory,
  categoriesFields,
  questions,
  unchecking,
  updateUncheck,
  onAddCategory,
  questionCategoryValue,
  importedCategories
}) => {
  const [questionCategories, setQuestionCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [question, setQuestion] = useState("");
  const [userData, setUserData] = useState([]);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [checkedValues, setCheckedValues] = useState(categoriesFields);
  const [completeRes,setCompleteRes] = useState([]);
  const [catNques,setCatNQues] = useState({});
  useEffect(() => {
    let token = window.localStorage.getItem("access");
    axios
      .get("question_category_mcq_list/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        setCompleteRes(res.data)
        const Data = res?.data?.map((item) => ({
          label: item.mcq_category,
          value: item.mcq_category,
        }));
        setUserData(Data);
        setQuestionCategories(res?.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setAuth({});
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);
  useEffect(() => {
    if (questionCategories.length > 0 && categoriesFields.length > 0) {
      onChangeCat(categoriesFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionCategories]);
  useEffect(()=>{
  },[unchecking])
  const onChangeCat = (newList) => {
    onAddCategory(newList)
    const prevCheckedValues = checkedValues;

    // assuming this section of code removing checked categories from unchecking state

    const removeFromUnchecking = (unchecking, newList) => {
      return unchecking.filter(item => !newList.includes(item));
    };
    // console.log("newList before remove", newList);
    const updatedUnchecking = removeFromUnchecking(unchecking, newList);
    // console.log("updatedUnchecking after remove", updatedUnchecking);
    updateUncheck(updatedUnchecking)
    // console.log("updatedUnchecking after remove 2", updatedUnchecking);
    setCheckedValues(newList);
    // console.log("newList", newList);

    ///////////////// end of section /////////////////////////////////

    // Getting questions from DB against selected category
    
    let newQuestions = {};
    questionCategories?.forEach((categoryObj) => {
        if (newList.includes(categoryObj?.mcq_category)) {
            if (!newQuestions[categoryObj.mcq_category]) {
                newQuestions[categoryObj.mcq_category] = [];
            }
            newQuestions[categoryObj.mcq_category] = [
                ...newQuestions[categoryObj.mcq_category],
                ...categoryObj.results_question
            ];
        }
    });

    ///////////////// end of section /////////////////////////////////

    const addedCategory = newList.find(cat => !prevCheckedValues.includes(cat));
    const removedCategory = prevCheckedValues.find(cat => !newList.includes(cat));

    
    if (newList.includes(addedCategory)){
      // works on add category
      if (Object.keys(questions).indexOf(addedCategory) < 0)
        {
          setCatNQues(newQuestions);
          onAddQuestion(newQuestions);
        }
        
    }
    else{
      setCatNQues(questions);
      onAddQuestion(questions);
    }
    categoryNquestions(newQuestions);
    addCategory(newList);
    
    const uncheckCategory = prevCheckedValues.find(cat => !newList.includes(cat));
    if (uncheckCategory) {
        uncheckedCategory(uncheckCategory)
    }
};

  return (
    <>
      <div className="mainDiv">
        <Col>
          <Title level={3} className="">
            Select Question Category:&nbsp;
            {importedCategories.length > 0 && (
                <span>
                   (Items in <span style={{ color: 'aquamarine' }}>light Green</span> are available for question generation)
                </span>
            )}
          </Title>
        </Col>
        <div className="mb-2 d-lg-flex d-md-flex">
          <CheckboxGroup
              options={userData.map(option => ({
                label: <span className={importedCategories.includes(option.label) ?  "imported_category" : ""}>{option.label}</span>,
                value: option.value
              }))}
              value={checkedValues}
              onChange={onChangeCat}
          />
        </div>
        {Object.values(catNques).length>0 && <>
          {questionCategoryValue.length>0 &&<div>
          <Col>
            <Title level={3} className="">
              Select Questions:
            </Title>
          </Col>
        </div>}
        </>}
      </div>
    </>
  );
};

const Header = ({ name }) => (
  <header
    className="text-center d-xl-flex justify-content-center mb-lg-4 mb-md-4 mb-3 mt-lg-0 mt-md-0 mt-4"
    id="header-img"
  >
    <div className="col-xl-3  col-lg-12 col-md-12 px-4">
      <img
        src="/img/new_logo.png"
        alt=""
        width="320px"
        style={{ margin: "auto", maxHeight: "300px",marginBottom:"1rem" }}
      />
      <p style={{ color: "white" }}>
        The future of question writing in medicine
      </p>
      {/* <div className="d-flex justify-content-center">
        <FontAwesomeIcon icon={faEnvelope} color="#fff" className="pe-2 pt-1" />
        <p style={{ color: "white" }}>issamfrancis@gmail.com</p>
      </div> */}
    </div>

    <div className="col-xl-6 col-lg-12 col-md-12">
      <div
        className="mt-lg-0 mt-md-0 p-xl-5 p-lg-5 p-md-5 p-4 pt-lg-3 pt-md-3 pt-2"
        style={{
          color: "white",
        }}
      >
        <Title style={{ color: "white" }}>{name}</Title>
        <p style={{ color: "white" }} className="mb-1">
          Dr. Issam Francis M.D. FRCPath © {new Date().getFullYear()} All Rights
          Reserved
        </p>
        {/* <p style={{ color: "white" }} className="mb-1">
          The future of question writing in medicine
        </p> */}
        <div className="d-flex justify-content-center d-lg-none d-block">
          <FontAwesomeIcon
            icon={faEnvelope}
            color="#fff"
            className="pe-2 pt-1"
          />
          <p style={{ color: "white" }}>issamfrancis@gmail.com</p>
        </div>
      </div>
    </div>

    <div className="col-xl-3 col-lg-12 d-xl-block d-none">
      <img
        src="/img/6.png"
        alt=""
        width="auto"
        style={{ margin: "auto", maxHeight: "201px" }}
        className="d-lg-block d-none"
      />
    </div>
  </header>
);

const Chip = ({ item, field, onRemove, index }) => {
  const handleClose = () => {
    onRemove(field, item);
  };
  return (
    <Tag
      key={index}
      closable={true}
      onClose={handleClose}
      className="d-flex align-items-center mb-md-1 mb-1"
      style={{ width: "fit-content" }}
    >
      <span>{item}</span>
    </Tag>
  );
};
const ChipInput = ({
  visible,
  value,
  field,
  onSaveRef,
  onToggleVisb,
  onInputChange,
  onInputSubmit,
}) => {
  const [newFInput,setNewFInput] = useState(false);
  const containerRef = React.useRef(null);
  const saveRef = (ref) => {
    onSaveRef(field, ref);
  };
  const showInput = () => {
    onToggleVisb(field, true);
  };
  const inputChange = (e) => {
    onInputChange(field, e.target.value);
  };
  const inputSubmit = () => {
    onInputSubmit(field);
    setNewFInput(true);
  };
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setNewFInput(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return <div ref={containerRef}>
    {(newFInput || visible) ? (
    <Input
      ref={saveRef}
      type="text"
      size="small"
      className="tag-input"
      value={value}
      onChange={inputChange}
      onBlur={inputSubmit}
      onPressEnter={inputSubmit}
    />
  ) : (
    <Tag
      className="site-tag-plus d-flex align-items-center"
      onClick={showInput}
      id="addNew"
    >
      <PlusOutlined className="me-1" /> Add New
    </Tag>
  )}
  </div>
};
const SelectStoryCategories = ({ index, value, onInputChange, onSaveRef }) => {
  const saveRef = (ref) => {
    onSaveRef(index, ref);
  };
  const inputChange = (e) => {
    onInputChange(index, e.target.value);
  };
  return (
    <select
      className="selectStoryCategories"
      ref={saveRef}
      onChange={inputChange}
      value={value}
    >
      <option>choose...</option>
      <option>category 1</option>
      <option>category 2</option>
      <option>category 3</option>
    </select>
  );
};
const ShowInput = ({
  value,
  index,
  onRemove,
  onInputChange,
  onInputSubmit,
  onSaveRef,
}) => {
  const saveRef = (ref) => {
    onSaveRef(index, ref);
  };
  const handleClose = () => {
    onRemove(index);
  };
  const inputChange = (e) => {
    onInputChange(index, e.target.value);
  };
  const inputSubmit = () => {
    onInputSubmit(index);
  };
  return (
    <Row className="mb-2">
      <Col flex="1">
        <TextArea
          ref={saveRef}
          onChange={inputChange}
          onPressEnter={inputSubmit}
          placeholder={`eg. A (age) year old (gender) presents with (clinical) is admitted to hospital. Story ${index + 1
            }`}
          autoSize={{ minRows: 2 }}
          value={value}
        />
      </Col>

      <Col>
        <CloseCircleFilled
          className="dynamic-delete-button"
          onClick={handleClose}
        />
      </Col>
    </Row>
  );
};
const StoryInput = ({
  value,
  index,
  onRemove,
  onInputChange,
  onSaveRef,
}) => {
  const saveRef = (ref) => {
    onSaveRef(index, ref);
  };
  const handleClose = () => {
    onRemove(index);
  };
  const inputChange = (e) => {
    onInputChange(index, e.target.value);
  };
  return (
    <Row className="mb-2 d-flex" key={index}>
      <Col flex="1">
        <TextArea
          ref={saveRef}
          onChange={inputChange}
          placeholder={`eg. A [age] [gender] presents with [clinical] is admitted to hospital. Story ${index + 1
            }`}
          autoSize={{ minRows: 2 }}
          value={value}
        />
      </Col>

      <Col className="d-flex align-items-center">
        <CloseCircleFilled
          className="dynamic-delete-button"
          onClick={handleClose}
        />
      </Col>
    </Row>
  );
};
const OptionSection = ({
  value,
  index,
  category,
  onRemove,
  categoriesFields,
  onSaveRef,
  onInputChange,
  onInputSubmit,
  onLoad,
  imported,
  genderSelect,
}) => {
  const saveRef = (ref) => {
    onSaveRef(index, ref);
  };

  const handleClose = () => {
    onRemove(category, index);
  };

  const inputChange = (e) => {
    onInputChange(category, index, e);
  };

  const inputSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onInputSubmit(index, category);
    }
  };

  // Directly invoke the onLoad function based on conditions
  const initializeData = () => {
    if (!value?.category && !imported) {
      onLoad(category, index, "", "title");
      onLoad(
        category,
        index,
        genderSelect?.includes("both") ? "both" : genderSelect[0],
        "gender"
      );
      onLoad(category, index, false, "isCorrect");
    } else {
      onLoad(category, index, value?.gender ?? "", "gender");
      onLoad(category, index, false, "isCorrect");
    }
  };

  // Call initializeData when necessary
  if (!value || !value?.gender) {
    initializeData();
  }

  return (
    <Row gutter={8} className="mb-2" key={index}>
      <Col flex="2">
        <label className="mb-2" id="title">
          Option
        </label>
        <div className="d-flex">
          <TextArea
            ref={saveRef}
            autoSize={{ minRows: 1, maxRows: 6 }}
            value={value?.title}
            name={"title"}
            placeholder={`Option ${index + 1}`}
            onChange={inputChange}
            // onPressEnter={inputSubmit}
            onKeyPress={inputSubmit}
          />
          <CloseCircleFilled
            className="dynamic-delete-button"
            onClick={handleClose}
          />
        </div>
      </Col>
      {genderSelect.length > 0 && (
        <Col flex="1">
          <label id="title" style={{paddingBottom:"0.5rem"}}>Gender</label>
          <select
            className="categoryOptions"
            name="gender"
            value={value?.gender}
            onChange={inputChange}
          >
            {genderSelect.map((item, i) => {
              return (
                <option key={i} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </Col>
      )}
    </Row>
  );
};

const CorrectOptionSection = ({
  value,
  index,
  category,
  onRemove,
  onSaveRef,
  onInputChange,
  onInputSubmit,
  categoriesFields,
  genderSelect,
  onLoad,
  imported,
}) => {
  const saveRef = (ref) => {
    onSaveRef(index, ref);
  };
  const handleClose = () => {
    onRemove(category, index);
  };
  const inputChange = (e) => {
    onInputChange(category, index, e);
  };
  const inputSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onInputSubmit(index, category);
    }
  };

  // Directly invoke the onLoad function based on conditions
  const initializeData = () => {
    if (!value?.category && !imported) {
      onLoad(category, index, "", "title");
      onLoad(
        category,
        index,
        genderSelect?.includes("both") ? "both" : genderSelect[0],
        "gender"
      );
      onLoad(category, index, true, "isCorrect");
    } else {
      onLoad(category, index, value?.gender ?? "", "gender");
      onLoad(category, index, true, "isCorrect");
    }
  };

  // Call initializeData when necessary
  if (!value || !value?.gender) {
    initializeData();
  }

  return (
    <Row gutter={8} className="mb-2" key={index}>
      <Col flex="2">
        <label className="mb-2" id="title">
          Option
        </label>
        <div className="d-flex">
          <TextArea
            ref={saveRef}
            autoSize={{ minRows: 1, maxRows: 6 }}
            value={value?.title}
            name={"title"}
            placeholder={`Option ${index + 1}`}
            onChange={inputChange}
            onKeyPress={inputSubmit}
          />
          <CloseCircleFilled
            className="dynamic-delete-button"
            onClick={handleClose}
          />
        </div>
      </Col>
      {genderSelect.length > 0 && (
        <Col flex="1">
          <label id="title" style={{paddingBottom:"0.5rem"}}>Gender</label>
          <select
            className="categoryOptions"
            name="gender"
            value={value?.gender}
            onChange={inputChange}
          >
            {genderSelect.map((item, i) => {
              return (
                <option key={i} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </Col>
      )}
    </Row>
  );
};

const CorrectOptions = ({
  value,
  index,
  category,
  onInputChange,
  onSaveRef,
}) => {
  const saveRef = (ref) => {
    onSaveRef(index, ref);
  };
  const inputChange = (e) => {
    onInputChange(category, e.target.value);
  };

  return (
    <Row gutter={8} className="mb-2">
      <Col flex="2">
        <label id="title">Title</label>
        <TextArea
          ref={saveRef}
          onChange={inputChange}
          onPressEnter={inputChange}
          placeholder={`Correct Option ${index + 1}`}
          autoSize={{ minRows: 1, maxRows: 10 }}
          value={value}
        />
      </Col>
      <Col flex="1">
        <Row>
          <label id="title">Category</label>
        </Row>
        <Input type="text" value={category} />
      </Col>
    </Row>
  );
};

const AnswerInput = ({
  value,
  index,
  onRemove,
  categoriesFields,
  onSaveRef,
  onInputChange,
  tagfields,
  onLoad,
  selectedFeatures,
  onInputSubmit,
}) => {
  const saveRef = (ref) => {
    onSaveRef(index, ref);
  };
  const handleClose = () => {
    onRemove(index);
  };
  const inputChange = (e) => {
    e.preventDefault();
    onInputChange(index, e);
  };
  const inputSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onInputSubmit(index);
    }
  };
  useEffect(() => {
    if (!value?.category) {
      onLoad(index, categoriesFields[0], "category");
    }
    Object.entries(tagfields).map((tagname, i) => {
      if (!value?.[tagname[0]]) {
        onLoad(index, tagname[1][0], tagname[0]);
      }
      return null;
    });
  }, [categoriesFields, tagfields, index, onLoad, value]);

  return (
    <Row gutter={8} className="mb-2">
      <Col flex="2">
        <label id="title">Option</label>
        <TextArea
          ref={saveRef}
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={value?.title}
          name="title"
          placeholder={`Option ${index + 1}`}
          onKeyPress={inputSubmit}
          onChange={inputChange}
          onPressEnter={inputChange}
        />
      </Col>

      {tagfields &&
        Object.entries(tagfields).map((tagname, i) => {
          if (selectedFeatures.includes(tagname)) {
          }
          return (
            <Col flex="1" key={i} className="mx-3 ">
              <Row>
                <label>{tagname[0]}</label>
              </Row>
              <select
                id="cars"
                className="categoryOptions"
                name={tagname[0]}
                onChange={inputChange}
              >
                {tagname[1].map((tagnames, i) => {
                  return <option value={tagnames}>{tagnames}</option>;
                })}
              </select>
            </Col>
          );
        })}
      <Col flex="1">
        <label id="title">Categories</label>
        <select
          id="cars"
          className="categoryOptions"
          name="category"
          onChange={inputChange}
        >
          {categoriesFields.map((item, i) => {
            return (
              <option key={i} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      </Col>

      <CloseCircleFilled
        className="dynamic-delete-button"
        onClick={handleClose}
      />
    </Row>
  );
};
const PreviewDataDelete = ({ index, onRemove }) => {
  const handleClose = () => {
    onRemove(index);
  };
  return (
    <div className="top-right-delete-button">
      <CloseCircleFilled
        className="dynamic-delete-button"
        onClick={handleClose}
      />
    </div>
  );
};

export {
  PreviewDataDelete,
  AnswerInput,
  OptionSection,
  StoryInput,
  Header,
  Chip,
  ChipInput,
  CorrectOptions,
  SelectStoryCategories,
  ShowInput,
  CorrectOptionSection,
  QuesionMode,
  Topic,
};
