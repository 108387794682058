import React from "react";
import ReactDOM from "react-dom/client"
import Application from "./App";
import ScrollButton from "./components/ScrollButton";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <React.StrictMode>
      <Application/>
      <ScrollButton/>
  </React.StrictMode>
);