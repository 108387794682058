import React, { useState, useRef, useEffect } from "react";
import { Header } from "./OtherComp";
import axios from "../api/axios";
import { ColorRing } from "react-loader-spinner";
import Main from "./components/QuestionPage";
import MCQ from "./components/MCQ";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Navigationbuttons from "./navigationbuttons";
import useAuth from "./logincomponents/hooks/useAuth";
import CustomSideBar from "./CustomSideBar";

const cookies = new Cookies();
const AIG = (props) => {
  const [puff, setPuff] = useState(false);
  const showCatRef = useRef(0);
  const [mcqCategory, setMcqCategory] = useState("");
  const [categories, setCategories] = useState();
  const [noOfMcq, setNoOfMcq] = useState("");
  const [questionCategory, setQuestionCategory] = useState("");
  const [showCat, setShowCat] = useState([]);
  const [MCQsItems, setMCQsItems] = useState();
  const [userData, setUserData] = useState();
  const [hideButton,setHideButton] = useState(false);
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const empty = [];
  useEffect(() => {
    let token = window.localStorage.getItem("access");
    axios
      .get("category_mcq_list/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        const Data = res?.data?.map((item) => ({
          label: item.mcq_category,
          value: item.mcq_category,
        }));
        setUserData(Data);
        setCategories(res.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setAuth({});
          window.localStorage.setItem("isLoggedIn", "false");
          navigate('/login')
        }
      });
  }, [setAuth, navigate]);
  const num = () => {
    for (let i = 0; i < categories?.length; i++) {
      if (mcqCategory === categories[i]?.mcq_category) {
        return categories[i]?.results;
      }
    }
  };
  const userData1 = num()?.map((item) => ({
    label: item.questionCategory__name,
    value: item.questionCategory__name,
  }));
  const getData = () => {
    let body;
    if (!showCat) {
      body = [
        {
          topic: mcqCategory,
          numberOfMCQs: noOfMcq,
          questionCategory: questionCategory,
        },
      ];
    } else {
      body = showCat.concat({
        topic: mcqCategory,
        numberOfMCQs: noOfMcq,
        question_category: questionCategory,
      });
    }
    let token = window.localStorage.getItem("access");
    axios
      .post("fetch_mcq_by_category/", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        setMCQsItems(res.data);
        setPuff(false);
      })
      .catch((err) => {
        setPuff(false);
        if (err?.response?.status === 401) {
          setAuth({});
          window.localStorage.setItem("isLoggedIn", "false");
          navigate('/login')
        }
      });
  };
  const HandleChange = () => {
    setHideButton(false)    
    setPuff(true);
    let data = {
      topic: mcqCategory,
      numberOfMCQs: noOfMcq,
      question_category: questionCategory,
    };
    setShowCat((prev) => {
      return [...prev, data];
    });
    setMcqCategory("");
    setQuestionCategory("");
    setNoOfMcq("");
    getData();
  };
  const HandleRemove = (index) => {
    setHideButton(true)
    if (showCat.length > 1) {
      const result = showCat.filter((elem, i) => {
        return i !== index;
      });
      setShowCat(result);
      axios
        .post("fetch_mcq_by_category/", result, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access")}`,
          },
          withCredentials: true,
        })
        .then((res) => {
          setMCQsItems(res.data);
          setPuff(false);
        })
        .catch((err) => {
          setPuff(false);
          if (err?.response?.status === 401) {
            setAuth({});
            window.localStorage.setItem("isLoggedIn", "false");
            navigate('/login')
          }
        });
    } else {
      setShowCat(empty);
      setMCQsItems(empty);
    }
  };
  return (
    <>
      <main className="app">
        <CustomSideBar />
        <Header name="Automatic Item Generator v.3 (AIG-3)" />
        <Navigationbuttons />
        {!categories && (
          <center>
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </center>
        )}
        {categories && (
          <Main
            userData1={userData1}
            userData={userData}
            mcqCategory={mcqCategory}
            questionCategory={questionCategory}
            noOfMcq={noOfMcq}
            showCatRef={showCatRef}
            showCat={showCat}
            categories={categories}
            HandleChange={HandleChange}
            HandleRemove={HandleRemove}
            setMcqCategory={setMcqCategory}
            setNoOfMcq={setNoOfMcq}
            setQuestionCategory={setQuestionCategory}
            num={num}
            setPuff={setPuff}
            puff={puff}
          />
        )}
        <MCQ MCQsItems={MCQsItems} hideButton={hideButton} /> 
      </main>
    </>
  );
};
export default AIG;
