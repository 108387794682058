import React from "react";
import App from "./components/App";
import AIG from "./components/QuestionPage";
import { BrowserRouter, Route, Routes, } from "react-router-dom"
import { AuthProvider } from "./components/logincomponents/context/AuthProvider";
import RequireAuth from "./RequireAuth";
import Login from "./components/logincomponents/login";
const Application = () => {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<RequireAuth />}>
              <Route path="/" element={<App />} />
              <Route path="/question-bank" element={<AIG />} />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
};
export default Application;
