const initialState = {
  puff: false,
  responseFromQues: {},
  story: [""],
  storyCatFields: [],
  apiData: [],
  tempCat: [],
  storyIndex: [],
  storyCategoriesFields: [],
  topic: '',
  question: [],
  MCQs: [],
  questionSubmitForm: [],
  questionformchip: {},
  correctOptions: [],
  incorrectOptions: [],
  correctMultipleOptions: {},
  questionCategoryValue: [],
  possibleCases: 0,
  questionTagsValue: [],
  selectedFeatures: [],
  answers: {},
  data: [],
  answersData: [],
  fields: [],
  tagfields: {},
  chiptagbtn: false,
  categoriesFields: [],
  categoriesInputVisible: true,
  inputVisible: {},
  inputValue: {},
  imported: false,
  isGenerated: false,
  genderSelect: [],
  MCQsItems: {},
  totalMCQs: 0,
  topicCheck: false,
  minAgeInDays: 0,
  maxAgeInDays: 0,
  minAgeInWeeks: 0,
  maxAgeInWeeks: 0,
  minAgeInMonths: 0,
  maxAgeInMonths: 0,
  minAgeInYears: 0,
  maxAgeInYears: 0,
  questionInputs: {},
  genertaeNowMCQ: false,
  pickedMCQItems: [],
  unchecking: [],
  tempStory: [""],
  importedCategories: []
}
export default initialState;