import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import useAuth from "./logincomponents/hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "../api/axios";
import Cookies from "universal-cookie";
import useAxiosPrivate from "./logincomponents/hooks/useAxiosPrivate"
const cookies = new Cookies();
const Navigationbuttons = ({ showFile }) => {
    const axiosPrivate = useAxiosPrivate()
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [username, setUsername] = useState('')
    const [profileImage,setProfileImage]=useState('')
    useEffect(() => {
        axios.get('fetch_user_info/', {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${window.localStorage.getItem("access")}`,
            },
            withCredentials: true,
        }).then(res => {
            setProfileImage(res?.data?.image)
            setUsername(res?.data?.username)
        }).catch(err => {
            if (err?.response?.status === 401) {
                console.log(err)
                window.localStorage.setItem("isLoggedIn", "false");
                navigate("/login")
            }
        })
    }, [navigate])
    const logout = () => {
        axios.post("logout/", {}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${window.localStorage.getItem("access")}`,
            },
            withCredentials: true,
        }).then(res => {
            setAuth({});
            window.localStorage.setItem("isLoggedIn", "false");
            navigate("/login");
        }).catch(err => {

        })
    };
    return (
        <>
            <div className="d-lg-block d-md-block d-none">
                <div
                    className="row align-items-center py-2"
                    style={{
                        border: "2px solid white",
                        borderRight: "none",
                        borderLeft: "none",
                    }}
                    id="headerBt"
                >
                    <div className="d-flex justify-content-center col-lg-3 col-md-2">
                        <div className="d-lg-block d-none">
                            <div className="d-flex ">
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    color="#fff"
                                    className="pe-2 pt-1"
                                />
                                <p style={{ color: "white" }} className="mb-0">
                                    issamfrancis@gmail.com
                                </p>
                            </div>
                        </div>
                    </div>
                    {location.pathname !== "/question-bank" ? (
                        <div className="d-lg-flex d-md-flex justify-content-around p-2 btn_group col-lg-6 col-md-8 px-xl-5 px-lg-4  d-lg-block d-md-block d-none">
                            <button
                                htmlFor="files"
                                className="btn col-xl-2 col-lg-3 col-md-3 col-6 import"
                                style={{
                                    backgroundColor: "whitesmoke",
                                    color: "black",
                                    padding: "0.7rem 3rem",
                                    margin: "4px 2px",
                                    cursor: "pointer",
                                    borderRadius: "3.2rem",
                                    whiteSpace: "nowrap",
                                    width: "calc(100% - 52%)",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                onClick={() => {
                                    document.getElementById("file-input").click();
                                }}
                            >
                                Import MCQ Template
                                <input
                                    id="file-input"
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={(e) => showFile(e)}
                                />
                            </button>
                            <button
                                htmlFor="files"
                                className="btn col-xl-2 col-lg-3 col-md-3 col-6 import"
                                style={{
                                    backgroundColor: "whitesmoke",
                                    color: "black",
                                    padding: "0.7rem 3rem",
                                    margin: "4px 2px",
                                    cursor: "pointer",
                                    borderRadius: "3.2rem",
                                    whiteSpace: "nowrap",
                                    width: "calc(100% - 52%)",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                onClick={() => navigate("/question-bank")}
                            >
                                Go To Question Bank
                            </button>
                        </div>
                    ) : (
                        <div className="mx-auto d-flex justify-content-lg-center justify-content-md-center  justify-content-center  col-lg-6 col-md-8">
                            <Link
                                to={"/"}
                                htmlFor="files"
                                className="btn automatic"
                                style={{
                                    backgroundColor: "whitesmoke",
                                    color: "black",
                                    padding: "12px 32px",
                                    margin: "4px 2px",
                                    cursor: "pointer",
                                    borderRadius: "3.2rem",
                                    whiteSpace: "nowrap",
                                    width: "calc(100% - 19%)",
                                }}
                            >
                                Open Automatic Item Generator (AIG)
                            </Link>
                        </div>
                    )}
                    <div className="col-lg-3 col-md-2 d-lg-block d-md-block d-none">
                        <div
                            className="d-flex align-items-center justify-content-center"
                            id="user"
                        >
                            <p style={{ color: "white" }} className="mb-0 d-lg-block d-none me-1">
                                {username}
                            </p>
                            <DropdownButton
                                id="dropdown-item-button"
                                title={
                                    <img src={profileImage} alt="" id="prImg"/>
                                }
                            >
                                <Dropdown.Item as="button" onClick={() => logout()}>
                                    Sign out
                                </Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>
                </div>
            </div>
            {location.pathname !== "/question-bank" ? (
                <div className="d-flex justify-content-around p-2 btn_group col-lg-6 col-md-8 d-lg-none d-md-none d-block mb-5">
                    <button
                        htmlFor="files"
                        className="btn col-xl-2 col-lg-3 col-md-3 col-6 import"
                        style={{
                            backgroundColor: "whitesmoke",
                            color: "black",
                            padding: "0.7rem 3rem",
                            margin: "4px 2px",
                            cursor: "pointer",
                            borderRadius: "3.2rem",
                            whiteSpace: "nowrap",
                            width: "calc(100% - 52%)",
                            display: "flex",
                            justifyContent: "center",
                        }}
                        onClick={() => {
                            document.getElementById("file-input").click();
                        }}
                    >
                        Import Template
                        <input
                            id="file-input"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => showFile(e)}
                        />
                    </button>
                    <button
                        htmlFor="files"
                        className="btn import"
                        style={{
                            backgroundColor: "whitesmoke",
                            color: "black",
                            padding: "0.7rem 3rem",
                            margin: "4px 2px",
                            cursor: "pointer",
                            borderRadius: "3.2rem",
                            whiteSpace: "nowrap",
                            width: "calc(100% - 53%)",
                            display: "flex",
                            justifyContent: "center",
                        }}
                        onClick={() => navigate("/question-bank")}
                    >
                        Question Bank
                    </button>
                    <input
                        id="files"
                        style={{ visibility: "hidden", display: "none" }}
                        type="file"
                        onChange={(e) => this.showFile(e)}
                    />
                </div>
            ) : (
                <div className="d-lg-none d-md-none d-block">
                    <div className="mx-auto d-flex justify-content-lg-center justify-content-md-center  justify-content-center  col-lg-6 col-md-8">
                        <Link
                            to={"/"}
                            htmlFor="files"
                            className="btn automatic"
                            style={{
                                backgroundColor: "whitesmoke",
                                color: "black",
                                padding: "12px 32px",
                                margin: "4px 2px",
                                cursor: "pointer",
                                borderRadius: "3.2rem",
                                whiteSpace: "nowrap",
                                width: "calc(100% - 19%)",
                            }}
                        >
                            Open Automatic Item Generator (AIG)
                        </Link>
                    </div>
                </div>
            )}
        </>
    );
};

export default Navigationbuttons;
