import axios from "../api/axios";
import {Blocks, ColorRing} from "react-loader-spinner";
import { saveAs } from "file-saver";
import { Accordion } from "react-bootstrap";
import numberToWords from "number-to-words";
import { CloseCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
  StoryInput,
  Header,
  Chip,
  ChipInput,
  OptionSection,
  CorrectOptionSection,
  QuesionMode,
  Topic,
} from "./OtherComp";
import { Typography, Button, Input, Row, Col } from "antd";
import {
  PlusOutlined,
  SafetyOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import initialState from "../initialState";
import { removeElAtIndex, removeOptionElAtIndex } from "../utility/main";
import { Outlet, useNavigate } from "react-router-dom";
import Navigationbuttons from "./navigationbuttons";
import CustomSideBar from "./CustomSideBar";
const { Title } = Typography;
const HEADING = {
  ageRange: "Age Range",
  story: "Clinical vignette/scenario",
  variables: "Question Stem Variable Entries",
  question: "Questions",
  Categories: "Question Categories",
  storyCategories: "Question topic",
  Correct: "Correct Options",
  Correct1: "Correct Options",
  options: "Other Options",
  optionSection: "Distractor Options",
  previewButton: "Generate Items",
  generateButton: "Download MCQs",
  addQuestionCategory: "Add Question Category",
  addQuestion: "Add Question",
};
const NavigateWrapper = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        await axios.get('fetch_user_info/', {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("access")}`,
          },
          withCredentials: true,
        });
        setIsLoading(false);
      } catch (err) {
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      }
    };

    fetchUserInfo();
  }, [navigate]);

  if (isLoading) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Blocks
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              visible={true}
          />
        </div>
    );
  }
  return <App navigate={navigate} />;
};
class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.sectionRef = React.createRef();
    this.topicInputRef = React.createRef();
    this.inputRefs = {};
  }
  realCatArray = [];
  catArray = [];
  chipInputRef = {};
  categoriesInputRef = React.createRef();
  storiesCategoryInputRef = React.createRef();
  answerInputRef = {};
  correctMultipleOptionsInputRef = {};
  questionInputRef = [];
  storyInputRef = [];
  correctOptionRef = [];
  state = initialState;
  componentDidMount() {
    this.onStoryInputSubmit();
    this.setState({
      chiptagbtn: false,
    });
  }
  onStoryCategoryInputChange = (index, newVal) => {
    const { storyCategoriesFields } = this.state;
    const newStory = storyCategoriesFields.slice();
    newStory[index] = newVal;
    this.setState({
      storyCategoriesFields: newStory,
    });
    this.resetData();
  };
  onStoryInputChange = (index, newVal) => {
    const { tempStory } = this.state;
    const newTempStory = [...tempStory];
    newTempStory[index] = newVal;
    this.setState({
      tempStory: newTempStory,
      pickedMCQItems: [],
    });
    this.resetData();
  };
  onStoryInputAdd = () => {
    const { tempStory } = this.state;
    this.setState(
      {
        tempStory: [...tempStory, ""],
        pickedMCQItems: [],
      },() => {
        const { tempStory } = this.state;
        this.storyInputRef[tempStory?.length - 1].focus();
      }
    );
    this.resetData();
  };
  onStoryInputSaveRef = (index, ref) => {
    this.storyInputRef[index] = ref;
  };
  onStoryCategoryInputSaveRef = (index, ref) => {
    this.storyInputRef[index] = ref;
  };
  onStoryRemove = (index) => {
    const { tempStory } = this.state;
    this.setState({
      tempStory: tempStory.filter((_, i) => i !== index),
      pickedMCQItems: [],
    })
    this.storyInputRef = removeElAtIndex(this.storyInputRef, index);
    this.resetData();
  };
  onStoryInputSubmit = () => {
    const { story, fields, tempStory } = this.state;
    let newFieldsArray = [];
    tempStory.forEach((itemStory) => {
      const items = (itemStory || "[]").trim().match(/\[[a-z0-9_-]+\]/gi) || [];
      items.forEach((item) => {
        const key = item.slice(1, -1);
        // Look for the variable added in existing variables
        const fieldObject = fields.find(field => field.key === key);
        newFieldsArray.push({ key, value: fieldObject ? fieldObject.value : [] });
      });
    });
    const uniqueFieldsArray = newFieldsArray.filter(
      (item, index, self) => index === self.findIndex((t) => t.key === item.key)
    );
    const updatedFields = uniqueFieldsArray.map((field) => ({
      ...field,
      value: Array.isArray(field.value) ? field.value : [],
    }));
    this.setState({
      story: tempStory,
      fields: updatedFields,
      chiptagbtn: true,
    });

    this.resetData();
  };
  onChipCategoriesInputChange = (e) => {
    const {
      categoriesFields,
      questionCategoryValue,
      answers,
      correctMultipleOptions,
    } = this.state;
    const initQuestionCategory = categoriesFields[0] || e.target.value;
    if (e.key === "Enter") {
      this.setState({
        categoriesFields: [...categoriesFields, e.target.value],
        questionCategoryValue: questionCategoryValue?.length
          ? [...questionCategoryValue]
          : [initQuestionCategory],
        answers: { ...answers, [e.target.value]: [{}] },
        correctMultipleOptions: {
          ...correctMultipleOptions,
          [e.target.value]: [{}],
        },
      });
      e.target.value = "";
    }

    this.resetData();
  };
  addCategory = (values) => {
    const { categoriesFields, answers, correctMultipleOptions } = this.state;
    const newCategoriesFields = [...categoriesFields];
    const newAnswers = { ...answers };
    const newCorrectMultipleOptions = { ...correctMultipleOptions };
    values.forEach((value) => {
      if (value && !newCategoriesFields.includes(value)) {
        if (!newAnswers[value]  && !newCorrectMultipleOptions[value]){
          newAnswers[value] = [{}];
          newCorrectMultipleOptions[value] = [{}];
        }
        newCategoriesFields.push(value);
      }
    });
    this.setState({
      categoriesFields: newCategoriesFields,
      answers: newAnswers,
      correctMultipleOptions: newCorrectMultipleOptions,
    });
    this.resetData();
  };
  categoryNquestions = (response) => {
    const { responseFromQues } = this.state;
    this.setState({
      responseFromQues: response,
    });
  };
  setCateg = () => {
    this.setState({
      storyCatFields: [this.state.tempCat],
    });
  };
  onChipStoryCategoriesInputChange = (value) => {
    this.setState({
      topic: value,
      pickedMCQItems: [],
    });
  };
  onStoryategoriesChipRemove = (i, field) => {
    const { storyCategoriesFields } = this.state;
    this.setState({
      storyCategoriesFields: storyCategoriesFields.filter((_, el) => el !== i),
    });
    this.resetData();
  };
  onCategoriesChipRemove = (i, field) => {
    const { categoriesFields, questionCategoryValue, answers, question } =
      this.state;
    let newAnswers = { ...answers };
    Object.keys(answers).map((cat) => {
      if (cat !== field) {
        newAnswers[field] = [];
      } else {
        delete newAnswers[field];
      }
      return null;
    });

    this.setState({
      categoriesFields: categoriesFields.filter((_, el) => el !== i),
      questionCategoryValue: questionCategoryValue.filter((_, el) => el !== i),
      question: question.filter((_, el) => el !== i),
      answers: { ...newAnswers },
      correctMultipleOptions: { ...newAnswers },
    });
    this.resetData();
  };
  onChipInputSaveRef = (field, ref) => {
    this.chipInputRef[field] = ref;
  };
  onChipInputToggleVisb = (field, visible) => {
    const { inputVisible } = this.state;
    const updatedInputVisible =
      typeof inputVisible === "object" ? inputVisible : {};
    this.setState(
      (prevState) => ({
        inputVisible: { ...prevState.inputVisible, [field]: visible },
      }),
      () => {
        if (this.chipInputRef && this.chipInputRef[field]) {
          this.chipInputRef[field].focus();
        }
      }
    );
    this.resetData();
  };
  onChipInputChange = (field, newVal) => {
    const { inputValue } = this.state;
    const updatedInputValue = typeof inputValue === "object" ? inputValue : {};
    this.setState({
      inputValue: { ...updatedInputValue, [field]: newVal },
      pickedMCQItems: [],
    });
    this.resetData();
  };

  onChipInputSubmit = (field) => {
    const { inputValue, fields, genderSelect, inputVisible } = this.state;
    const updatedInputValue = typeof inputValue === "object" ? inputValue : {};
    const newFieldItem = fields.find((item) => item.key === field);
    const newInputValueItem = updatedInputValue[field];
    let newFields = newFieldItem ? newFieldItem.value : [];
    if (newInputValueItem && newFields.indexOf(newInputValueItem) === -1) {
      newFields = [...newFields, newInputValueItem.trim()];
    }
    const newGenderSelect = field === "gender" ? newFields : genderSelect;
    this.setState({
      genderSelect: newGenderSelect,
      fields: fields.map((item) =>
        item.key === field ? { ...item, value: newFields } : item
      ),
      inputValue: { ...updatedInputValue, [field]: "" },
      inputVisible: { ...this.state.inputVisible, [field]: false },
      pickedMCQItems: [],
    });
    this.resetData();
  };

  onChipRemove = (field, tag) => {
    const { fields, genderSelect } = this.state;

    if (field === "gender") {
      this.setState({
        genderSelect: genderSelect.filter((el) => el !== tag),
        pickedMCQItems: [],
      });
    }

    this.setState((prevState) => ({
      fields: prevState.fields.map((item) =>
        item.key === field
          ? { ...item, value: item.value.filter((el) => el !== tag) }
          : item
      ),
      pickedMCQItems: [],
    }));

    this.resetData();
  };

  onCorrectOptionSaveRef = (index, ref) => {
    this.correctOptionRef[index] = ref;
  };
  onCorrectOptionInputChange = (category, newTitle) => {
    const { correctOptions } = this.state;
    this.setState({
      correctOptions: { ...correctOptions, [category]: newTitle },
    });
  };
  onQuestionInputAdd = () => {
    const { question, questionCategoryValue, categoriesFields } = this.state;
    this.setState(
      {
        question: question.concat(""),
        questionCategoryValue: [...questionCategoryValue, categoriesFields[0]],
      },
      () => {
        const { question } = this.state;
        this.questionInputRef[question?.length - 1].focus();
      }
    );
    this.resetData();
  };
  onAddQuestion = (quest) => {
    const { question } = this.state;
    this.setState({
      question: quest,
    });
    this.resetData();
  };
  onAddCategory= (category) => {
    const { questionCategoryValue } = this.state;
    this.setState({
      questionCategoryValue: [...category]
    })
  }
uncheckedCategory = (category) => {
  const { questionCategoryValue } = this.state;
  if (category) {
    this.setState(prevState => ({
      pickedMCQItems: [],
      unchecking: [
        ...prevState.unchecking,
        ...[category]
      ],
      questionCategoryValue: questionCategoryValue.filter(item=>!category.includes(item))
    }));
  }

}
updateUncheck = (updatedUnchecking) => {
  this.setState({
    unchecking: updatedUnchecking
  })
}
questionCategoryValueChangefun = (index, val) => {
    const { questionCategoryValue: questionCategories } = this.state;
    questionCategories[index] = val;
    this.setState({
      questionCategoryValue: questionCategories,
    });
  };
  questionTagsValueChangefun = (index, val) => {
    this.setState({
      questionTagsValue: [val],
    });
  };
  onQuestionInputSaveRef = (index, ref) => {
    this.questionInputRef[index] = ref;
  };
  onQuestionRemove = (index) => {
    const { question, questionCategoryValue } = this.state;
    this.setState({
      question: removeElAtIndex(question, index),
      questionCategoryValue: removeElAtIndex(questionCategoryValue, index),
    });
    this.questionInputRef = removeElAtIndex(this.questionInputRef, index);
    this.resetData();
  };
  onAnswersLoad = (category, index, newVal, key) => {
    setTimeout(() => {
      const { answers } = this.state;
      const newAnswers = { ...answers };
      newAnswers[category][index][key] = newVal;
      this.setState({
        answers: newAnswers,
      });
    }, 0);
  };


  onCorrectMultipleOptionsLoad = (category, index, newVal, key) => {
    setTimeout(() => {
      const { correctMultipleOptions } = this.state;
      const newAnswers = { ...correctMultipleOptions };
      newAnswers[category][index][key] = newVal;
      this.setState({
        correctMultipleOptions: newAnswers,
      });
    }, 0);
  };
  onAnswersInputChange = (category, index, e) => {
    const { answers } = this.state;
    const newAnswers = { ...answers };
    const { name, value } = e.target;
    newAnswers[category][index] = {
      ...newAnswers?.[category][index],
      [name]: value,
    };
    this.setState({
      answers: newAnswers,
      pickedMCQItems: [],
    });
    this.resetData();
  };
  onCorrectMultipleOptionsInputChange = (category, index, e) => {
    const { correctMultipleOptions } = this.state;
    const newAnswers = { ...correctMultipleOptions };
    const { name, value } = e.target;
    newAnswers[category][index] = {
      ...newAnswers?.[category][index],
      [name]: value,
    };
    this.setState({
      correctMultipleOptions: newAnswers,
      pickedMCQItems: [],
    });
    this.resetData();
  };
  onAnswersInputAdd = (index, category, fromButton = false) => {
    const { answers } = this.state;
    const categoryAnswers = answers[category] || [];
    if (fromButton || index === categoryAnswers.length - 1) {
      this.resetData();
      this.setState(
        (prevState) => ({
          answers: {
            ...prevState.answers,
            [category]: [
              ...categoryAnswers,
              {},
            ],
          },
          pickedMCQItems: [],
        }),
        () => {
          const { answers } = this.state;
          this.answerInputRef[answers[category]?.length - 1]?.focus();
        }
      );
    } else if (this.answerInputRef[index + 1]) {
      this.answerInputRef[index + 1].focus();
    }
  };
  onCorrectMultipleOptionsInputAdd = (index, category, fromButton = false) => {
    const { correctMultipleOptions } = this.state;
    const categoryOptions = correctMultipleOptions[category] || [];
    if (fromButton || index === categoryOptions.length - 1) {
      this.resetData();
      this.setState(
        (prevState) => ({
          correctMultipleOptions: {
            ...prevState.correctMultipleOptions,
            [category]: [
              ...categoryOptions,
              {},
            ],
          },
          pickedMCQItems: [],
        }),
        () => {
          const { correctMultipleOptions } = this.state;
          this.correctMultipleOptionsInputRef[correctMultipleOptions[category].length - 1]?.focus();
        }
      );
    } else if (this.correctMultipleOptionsInputRef[index + 1]) {
      this.correctMultipleOptionsInputRef[index + 1].focus();
    }
  };

  onAnswerRemove = (category, index) => {
    const { answers } = this.state;
    answers?.[category]?.splice(index, 1);
    this.setState({
      answers: { ...answers },
      pickedMCQItems: [],
    });
    this.answerInputRef = removeOptionElAtIndex(this.answerInputRef, category);
    this.resetData();
  };
  onCorrectMultipleOptionsRemove = (category, index) => {
    const { correctMultipleOptions } = this.state;
    correctMultipleOptions?.[category]?.splice(index, 1);
    this.setState({
      correctMultipleOptions: { ...correctMultipleOptions },
      pickedMCQItems: [],
    });
    this.correctMultipleOptionsInputRef = removeOptionElAtIndex(
      this.correctMultipleOptionsInputRef,
      category
    );
    this.resetData();
  };
  onAnswerInputSaveRef = (index, ref) => {
    this.answerInputRef[index] = ref;
  };
  oneGenderCase(g) {
    const { categoriesFields, correctMultipleOptions, answers } = this.state;
    let validCorrectOption;
    let validIncorrectOption;
    for (let i = 0; i < categoriesFields?.length; i++) {
      let totalNumberOfCorrectOptions = correctMultipleOptions[
        categoriesFields[i]
      ]?.filter((elem) => elem.gender === g && elem.title !== "");
      if (totalNumberOfCorrectOptions?.length > 0) {
        validCorrectOption = true;
      } else {
        validCorrectOption = false;
      }
      let totalNumberOfInCorrectOptions = answers[categoriesFields[i]]?.filter(
        (elem) => elem.gender === g && elem.title !== ""
      );
      if (totalNumberOfInCorrectOptions?.length > 3) {
        validIncorrectOption = true;
      } else {
        validIncorrectOption = false;
      }
      if (!validCorrectOption || !validIncorrectOption) {
        return { status: false, categoryName: categoriesFields[i] };
      }
    }
    return { status: true };
  }
  validCase() {
    const { categoriesFields, correctMultipleOptions, answers } = this.state;
    let validCorrectOption;
    let validIncorrectOption;
    for (let i = 0; i < categoriesFields?.length; i++) {
      let totalNumberOfCorrectOptionsForMale = correctMultipleOptions[
        categoriesFields[i]
      ]?.filter((elem) => elem.gender === "male" && elem.title !== "");
      let totalNumberOfCorrectOptionsForFemale = correctMultipleOptions[
        categoriesFields[i]
      ]?.filter((elem) => elem.gender === "female" && elem.title !== "");
      let totalNumberOfCorrectOptionsForBoth = correctMultipleOptions[
        categoriesFields[i]
      ]?.filter((elem) => elem.gender === "both" && elem.title !== "");
      if (totalNumberOfCorrectOptionsForBoth?.length > 0) {
        validCorrectOption = true;
      } else {
        if (
          totalNumberOfCorrectOptionsForMale?.length > 0 &&
          totalNumberOfCorrectOptionsForFemale?.length > 0
        ) {
          validCorrectOption = true;
        } else {
          validCorrectOption = false;
        }
      }
      let totalNumberOfInCorrectOptionsForMale = answers[
        categoriesFields[i]
      ]?.filter((elem) => elem.gender === "male" && elem.title !== "");
      let totalNumberOfInCorrectOptionsForFemale = answers[
        categoriesFields[i]
      ]?.filter((elem) => elem.gender === "female" && elem.title !== "");
      let totalNumberOfInCorrectOptionsForBoth = answers[
        categoriesFields[i]
      ]?.filter((elem) => elem.gender === "both" && elem.title !== "");
      if (totalNumberOfInCorrectOptionsForBoth?.length > 3) {
        validIncorrectOption = true;
      } else {
        if (
          totalNumberOfInCorrectOptionsForMale?.length > 3 &&
          totalNumberOfInCorrectOptionsForFemale?.length > 3
        ) {
          validIncorrectOption = true;
        } else {
          validIncorrectOption = false;
        }
      }
      if (!validCorrectOption || !validIncorrectOption) {
        return { status: false, categoryName: categoriesFields[i] };
      }
    }
    return { status: true };
  }
  onCorrectMultipleOptionsInputSaveRef = (index, ref) => {
    this.correctMultipleOptionsInputRef[index] = ref;
  };
  resetData = () => {
    this.setState({
      data: [],
      answersData: [],
      isGenerated: false,
      MCQsItems: [],
      imported: false,
      possibleCases: "",
    });
  };
  resetDataWithStories = () => {
    this.setState({
      data: [],
      answers: {},
      answersData: [],
      isGenerated: false,
      MCQsItems: [],
      correctMultipleOptions: {},
      question: [""],
      genderSelect: [],
      categoriesFields: [],
      questionCategoryValue: [],
      chiptagbtn: false,
      inputValue: {},
      inputVisible: false,
    });
  };
  resetDataWithAnswers = () => {
    this.setState({
      data: [],
      answersData: [],
      answers: {},
      isGenerated: false,
      MCQsItems: [],
    });
  };
  allPossibleVariablesCases(arr, currentIndex = 0, currentCombination = []) {
    if (currentIndex === arr.length) {
      return [currentCombination];
    }

    const currentArray = arr[currentIndex];
    const combinations = [];

    for (const element of currentArray) {
      if (element !== "both") {
        const newCombination = [...currentCombination, element];
        const subCombinations = this.allPossibleVariablesCases(
          arr,
          currentIndex + 1,
          newCombination
        );
        combinations.push(...subCombinations);
      }
    }

    return combinations;
  }
  getAgeAndAgeIn(str) {
    let age;
    let ageIn;
    const unitVariations = ["months", "weeks", "days", "years"];

    const parts = str.replace("-old", "").split("-"); // Remove "-old" and split by hyphen
    if (parts.length === 2) {
      age = parseInt(parts[0]); // Parse the number as an integer
      ageIn = parts[1].toLowerCase(); // Convert to lowercase for case-insensitivity

      // Check if ageIn is a valid unit variation and remove "-s" if present
      if (unitVariations.includes(ageIn)) {
        ageIn = ageIn.replace("s", ""); // Remove the "s" at the end if present
      }
      return { age, ageIn };
    }
  }

  getAllStories(allCases, story, fields, storiesCategory) {
    const allStories = [];
    const CS = [];
    const realCat = [];
    const variables = Object.keys(fields);
    const targets = ["age", "gender"];
    const ageIndex = variables.findIndex(
      (item) => item.toLowerCase() === targets[0].toLowerCase()
    );
    const genderIndex = variables.findIndex(
      (item) => item.toLowerCase() === targets[1].toLowerCase()
    );
    for (let i = 0; i < allCases?.length; i++) {
      let s = story;

      const currArray = allCases[i];
      for (let j = 0; j < currArray?.length; j++) {
        if (ageIndex !== -1 && genderIndex !== -1) {
          const ageValues = this.getAgeAndAgeIn(currArray[ageIndex]);
          if (ageValues.ageIn === "year") {
            if (ageValues.age > 19 && j === genderIndex) {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "man"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "woman"
                  : currArray[j]
              );
            } else if (ageValues.age <= 1 && j === genderIndex) {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "infant"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "infant"
                  : currArray[j]
              );
            } else {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "boy"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "girl"
                  : currArray[j]
              );
            }
          } else if (ageValues.ageIn === "month") {
            if (ageValues.age > 216 && j === genderIndex) {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "man"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "woman"
                  : currArray[j]
              );
            } else if (ageValues.age <= 12 && j === genderIndex) {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "infant"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "infant"
                  : currArray[j]
              );
            } else {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "boy"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "girl"
                  : currArray[j]
              );
            }
          } else if (ageValues.ageIn === "week") {
            if (ageValues.age < 5 && j === genderIndex) {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "neonate"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "neonate"
                  : currArray[j]
              );
            } else if (ageValues.age <= 52 && j === genderIndex) {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "infant"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "infant"
                  : currArray[j]
              );
            } else if (ageValues.age > 940 && j === genderIndex) {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "man"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "woman"
                  : currArray[j]
              );
            } else {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "boy"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "girl"
                  : currArray[j]
              );
            }
          } else if (ageValues.ageIn === "day") {
            if (ageValues.age < 29 && j === genderIndex) {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "neonate"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "neonate"
                  : currArray[j]
              );
            } else if (ageValues.age <= 365 && j === genderIndex) {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "infant"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "infant"
                  : currArray[j]
              );
            } else if (ageValues.age > 6570 && j === genderIndex) {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "man"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "woman"
                  : currArray[j]
              );
            } else {
              s = s.replace(
                "(" + variables[j] + ")",
                currArray[j] === "male" || currArray[j] === "boy"
                  ? "boy"
                  : currArray[j] === "female" || currArray[j] === "girl"
                  ? "girl"
                  : currArray[j]
              );
            }
          }
        } else {
          s = s.replace("(" + variables[j] + ")", currArray[j]); //.toLowerCase()
        }
      }
      this.setState({
        storyCat: storiesCategory,
      });

      allStories.push(this.extractNumberAfterAOrAn(s));
      let both = s.concat(`\n${storiesCategory}`);
      realCat.push(storiesCategory);
      CS.push(both);
    }
    this.setState({
      storyCat: CS,
    });
    let object = [];
    object.push({
      allstories: allStories,
      categories: CS,
      storyCategories: realCat,
    });
    return object;
  }
  removeExtraOptions(options) {
    if (options?.length > 4) {
      let arr = [];
      let j = 0;
      for (let i = 0; i < options?.length - 4; i++) {
        let ran = Math.floor(Math.random() * (options?.length - j));
        arr.push(ran);
        j++;
      }
      for (let i = 0; i < arr?.length; i++) {
        options.splice(arr[i], 1);
      }
    }
    return options;
  }
  shuffle(array) {
    let currentIndex = array?.length,
      randomIndex;
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }
  randomArrayShuffle(array) {
    var currentIndex = array?.length,
      temporaryValue,
      randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }
  onAnswersSubmit = async () => {
    const {
      minAgeInDays,
      maxAgeInDays,
      minAgeInWeeks,
      maxAgeInWeeks,
      minAgeInMonths,
      maxAgeInMonths,
      minAgeInYears,
      maxAgeInYears,
      genderSelect,
      story,
      answers,
      correctMultipleOptions,
      fields,
      questionCategoryValue,
      isGenerated,
      MCQsItems,
      categoriesFields,
      question,
      topic,
      apiData,
      totalMCQs,
      unchecking
    } = this.state;
    const agesObject = {};
    if (minAgeInDays !== 0 || maxAgeInDays !== 0) {
      agesObject.days = [+minAgeInDays, +maxAgeInDays];
    }
    if (minAgeInWeeks !== 0 || maxAgeInWeeks !== 0) {
      agesObject.weeks = [+minAgeInWeeks, +maxAgeInWeeks];
    }
    if (minAgeInMonths !== 0 || maxAgeInMonths !== 0) {
      agesObject.months = [+minAgeInMonths, +maxAgeInMonths];
    }
    if (minAgeInYears !== 0 || maxAgeInYears !== 0) {
      agesObject.years = [+minAgeInYears, +maxAgeInYears];
    }
    function excludeKeys(fields) {
      const excludedKeys = ["age", "gender"];
      const filteredFields = fields.filter(
        (field) => !excludedKeys.includes(field.key)
      );
      const result = {};
      filteredFields.forEach((field) => {
        result[field.key] = field.value;
      });
      return result;
    }
    const excludedResultFields = excludeKeys(fields);
    function flattenAndRemoveDuplicates(nestedArray) {
      const flattenedArray = nestedArray.flat();
      const uniqueArray = [...new Set(flattenedArray)];
      return uniqueArray;
    }
    const result = flattenAndRemoveDuplicates(questionCategoryValue);
    const removeItemFromArray = (array, itemToRemove) => {
      return array.filter(item => !itemToRemove.includes(item))
    };
    const newCategoriesValues = removeItemFromArray(result, unchecking);
    console.log("newCategoriesValues",newCategoriesValues)
    function constructQuestionJSON() {
      const questions = {};
      newCategoriesValues.forEach((category) => {
        const questionArray = question[category].map(q => q.question);
        const correctOptions = correctMultipleOptions[category].reduce((acc, option) => {
          if (!acc[option.gender]) {
            acc[option.gender] = [];
          }
          acc[option.gender].push(option.title);
          return acc;
        }, {});
        const incorrectOptions = answers[category].reduce((acc, option) => {
          if (!acc[option.gender]) {
            acc[option.gender] = [];
          }
          acc[option.gender].push(option.title);
          return acc;
        }, {});
        questions[category] = {
          question: questionArray,
          correct_options: correctOptions,
          incorrect_options: incorrectOptions
        };
      });

      console.log("Temp",questions)

      return questions;
    }
    const jsonObjectObject = constructQuestionJSON();
    const str = {
      ages: agesObject,
      genders: genderSelect,
      stories: story,
      variables: excludedResultFields,
      categories: newCategoriesValues,
      questions: jsonObjectObject,
    };
    const isEmpty = (value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      } else if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length === 0;
      }
      return !value;
    };
    const capitalizeAndSingularize = (key) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      if (capitalizedKey.endsWith('ies')) {
        return capitalizedKey.slice(0, -3) + 'y';
      } else if (capitalizedKey.endsWith('s') && !capitalizedKey.endsWith('ss')) {
        return capitalizedKey.slice(0, -1);
      }
      return capitalizedKey;
    };
    const getEmptyKeys = (obj) => {
      const emptyKeys = [];

      for (const [key, value] of Object.entries(obj)) {
        if (key === 'variables' && typeof value === 'object' && value !== null) {
          let isVariableEmpty = false;
          for (const variableKey in value) {
            if (isEmpty(value[variableKey])) {
              isVariableEmpty = true;
              break;
            }
          }
          if (isVariableEmpty) {
            emptyKeys.push('variables');
          }
        } else if (key === 'genders' && Array.isArray(value) && value.length === 0) {
          emptyKeys.push('genders');
        } else if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (isEmpty(item)) {
              emptyKeys.push(key);
            } else if (typeof item === 'object' && item !== null) {
              emptyKeys.push(...getEmptyKeys(item));
            }
          });
        } else if (typeof value === 'object' && value !== null) {
          if (isEmpty(value)) {
            emptyKeys.push(key);
          } else {
            emptyKeys.push(...getEmptyKeys(value));
          }
        } else if (isEmpty(value)) {
          emptyKeys.push(key);
        }
      }

      return [...new Set(emptyKeys)].map(capitalizeAndSingularize);
    };
    const emptyKeys = getEmptyKeys(str);
    if (emptyKeys.length > 0) {
      alert(`${emptyKeys.join(' ')} must not be empty`);
    } else {
      const token = window.localStorage.getItem("access");
      const { navigate } = this.props;
      try {
        const response = await axios.post(
          "get_possible_mcqs_combinations/",
          str,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        const mcqs = response.data.mcqs;
        const total_mcqs_created = response.data.total_mcqs_created;
        let data = {};
        let arr = [];
        for (let i = 0; i < mcqs.length; i++) {
          let incorrect_option = [];
          for (let j = 0; j < mcqs[i].incorrect_option?.length; j++) {
            incorrect_option.push(mcqs[i].incorrect_option[j]);
          }
          data = {
            topic: this.state.topic,
            mcq: mcqs[i].mcq,
            question: mcqs[i].question,
            correct_option: mcqs[i].correct_option,
            incorrect_option: incorrect_option,
            category: mcqs[i].category,
          };
          arr.push(data);
        }
        this.setState({
          isGenerated: true,
          MCQsItems: mcqs,
          totalMCQs: total_mcqs_created,
          apiData: arr,
        });
      } catch (error) {
        alert("Server Error!");
        if (error?.response?.status === 401) {
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      }
    }
  };
  onChipTagBtn = () => {
    this.state.fields &&
      this.setState({
        tagfields: this.state.fields,
      });
  };
  onSelectFeature = (fieldName, value) => {
    let arr = [...this.state.selectedFeatures];
    if (value) {
      return this.setState({
        selectedFeatures: [...this.state.selectedFeatures, fieldName],
      });
    } else {
      arr = arr.filter((e) => e !== fieldName);
      return this.setState({
        selectedFeatures: [...arr],
      });
    }
  };
  TextFile = (initialState) => {
    const element = document.createElement("a");
    const file = new Blob(initialState, { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  hasEmptyValues(obj) {
    return Object.values(obj).some(value => value === null || value === undefined || value === '');
  }

  downloadFile(str) {
    const blob = new Blob([JSON.stringify(str)], { type: "application/js" });
    saveAs(blob, "config.js");
  }
  sendData(str) {
    const { navigate } = this.props;
    let token = window.localStorage.getItem("access");
    axios
      .post("send_mcq_data/", str, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        this.setState({
          puff: false,
        });
        alert("Data has been saved in Database successfully!");
      })
      .catch((err) => {
        this.setState({
          puff: false,
        });
        alert("Server Error!");
        if (err?.response?.status === 401) {
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
  }
  downloadMcqFile(str) {
    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/plain;charset=utf-8," + encodeURI(str);
    hiddenElement.target = "_blank";
    hiddenElement.download = `MCQs.txt`;
    hiddenElement.click();
  }
  emptyFields = () => {
    for (let i = 0; i < Object.keys(this.state.fields)?.length; i++) {
      if (Object.keys(this.state.fields)[i]?.toLowerCase() !== "age") {
        if (Object.values(this.state.fields)[i]?.length < 1) {
          return true;
        }
      }
    }
    return false;
  };
  generateAndRender = () => {
    const {categoriesFields,unchecking} = this.state;
    const removeItemFromArray = (array, itemToRemove) => {
      return array.filter(item => !itemToRemove.includes(item))
    };
    const newCategoriesValues = removeItemFromArray(categoriesFields, unchecking);
    this.setState(
      { categoriesFields: newCategoriesValues },
      () => {
        console.log(this.state);
        if (this.state.topic === "") {
          alert("please specify a topic first");
          this.setState({ topicCheck: true }, () => {
            if (this.sectionRef.current) {
              this.sectionRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          });
        } else {
          this.setState({ puff: true }, () => {
            if (this.emptyFields()) {
              this.setState({ puff: false });
              alert("Question Stem Variable Entries must be non-empty");
            } else {
              this.setState({ puff: false }, () => {
                let invalidCase;
                if (this.state.genderSelect?.length > 0) {
                  if (this.state.genderSelect?.length === 1 && this.state.genderSelect[0] !== "both") {
                    invalidCase = this.oneGenderCase(this.state.genderSelect[0]);
                  } else {
                    invalidCase = this.validCase();
                  }
                  if (!invalidCase?.status) {
                    alert(`Please make sure you have added at least one correct option for each gender/both and at least 4 distractors for each gender/both against ${invalidCase?.categoryName}`);
                    return;
                  }
                }
                this.onAnswersSubmit();
              });
            }
          });
        }
      }
    );
  };

  generateLatexAndDownload = () => {
    const { pickedMCQItems } = this.state;
    console.log("pickedMCQItems", pickedMCQItems);
    let texStr = "";
  
    pickedMCQItems.forEach((dataEl, dataIndex) => {
      texStr += `${dataIndex + 1}. ${dataEl.mcq} \n`;
      texStr += `${dataEl.question}  \n`;
  
      // Combine correct and incorrect options
      const allOptions = [...dataEl.incorrect_option, dataEl.correct_option];
  
      // Shuffle the options
      const shuffledOptions = allOptions
        .map((option) => ({ option, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ option }) => option);
  
      // Add options to the LaTeX string
      shuffledOptions.forEach((option, MCQIndex) => {
        texStr += `${String.fromCharCode(
          "a".charCodeAt(0) + MCQIndex
        )}. ${option}`;
  
        // Mark the correct option with an asterisk
        if (option === dataEl.correct_option) {
          texStr += " *";
        }
        texStr += " \n";
      });
  
      texStr += "\n";
    });
  
    this.downloadMcqFile(texStr);
  };
  showCat = (data) => {
    for (let i = 0; i < data?.length; i++) return <>{data[i]}</>;
  };
  showFile = async (e) => {
    if (!e.target.value) {
      return;
    }
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        let safi;
        try {
          safi = JSON.parse(e.target.result);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          alert("There was an error reading the file. Please ensure it's a valid JSON format.");
          return;
        }
        const filteredGenderSelect = safi.genderSelect.filter((element) => element === "male" || element === "female" || element === "both")
        this.setState({
          answers: safi.answers,
          story: safi.story,
          tempStory: safi.story,
          question: safi.question,
          correctOptions: safi.correctOptions,
          questionCategoryValue: safi.questionCategoryValue,
          fields: safi.fields,
          chiptagbtn: safi.chiptagbtn,
          topic: safi.topic,
          categoriesFields: safi.categoriesFields,
          categoriesInputVisible: safi.categoriesInputVisible,
          inputVisible: safi.inputVisible,
          inputValue: safi.inputValue,
          isGenerated: false,
          genderSelect: filteredGenderSelect,
          correctMultipleOptions: safi.correctMultipleOptions,
          minAgeInDays: safi?.minAgeInDays ?? 0,
          maxAgeInDays: safi?.maxAgeInDays ?? 0,
          minAgeInWeeks: safi?.minAgeInWeeks ?? 0,
          maxAgeInWeeks: safi?.maxAgeInWeeks ?? 0,
          minAgeInMonths: safi?.minAgeInMonths ?? 0,
          maxAgeInMonths: safi?.maxAgeInMonths ?? 0,
          minAgeInYears: safi?.minAgeInYears ?? 0,
          maxAgeInYears: safi?.maxAgeInYears ?? 0,
          imported: true,
          importedCategories: safi.categoriesFields
        });
      };
      reader.readAsText(e.target.files[0]);
    } catch (error) {
      console.error("Error reading file:", error);
      alert("An unexpected error occurred while processing the file. Please try again.");
    }
  };
  startsWithEAfterAn(sentence) {
    // Use a regular expression to find 'An' or 'an' followed by a word starting with 'e'
    const regex = /\b(?:An|an)\s+e\w*/;

    // Test if the regex pattern matches the sentence
    return regex.test(sentence);
  }

  extractNumberAfterAOrAn(inputString) {
    // const match = inputString.match(/(?:A|An)\s+(\d+)/);
    const storyTextWithEnglishNumber = inputString.replace(
      /\b\d+\b/g,
      function (match) {
        const incrementedNumber = numberToWords.toWords(parseInt(match));
        return incrementedNumber.toString();
      }
    );
    const outputString = storyTextWithEnglishNumber
      .replace(/A (e\S*)/g, "An $1")
      .replace(/a (e\S*)/g, "an $1");
    // return match ? numberToWords.toWords(parseInt(match[1], 10)) : null;
    if (this.startsWithEAfterAn(outputString)) {
      const output = inputString.replace(
        /\b([Aa])\s(\d+)/g,
        (match, letter, number) =>
          letter === "A" ? `An ${number}` : `an ${number}`
      );

      return output;
    } else {
      return inputString;
    }
  }
  handleQuestionInputChange = (category, index, value) => {
    this.setState((prevState) => {
      const newQuestions = { ...prevState.question };
      newQuestions[category][index].question = value;
      return { question: newQuestions, pickedMCQItems: [] };
    });
    this.resetData();
  };
  handleRemoveQuestion = (category, index) => {
    this.setState((prevState) => {
      const newQuestions = { ...prevState.question };
      newQuestions[category] = newQuestions[category].filter((_, i) => i !== index);
      return { question: newQuestions, pickedMCQItems: [] };
    });
    this.resetData();
  };

  handleAddQuestion = (category) => {
    this.setState((prevState) => {
      const newQuestions = { ...prevState.question };
      if (!Array.isArray(newQuestions[category])) {
        newQuestions[category] = [];
      }
      newQuestions[category] = [...newQuestions[category], { question: '' }];
      return { question: newQuestions };
    },
    () => {
      const newIndex = this.state.question[category].length - 1;
      if (this.inputRefs[`${category}-${newIndex}`]) {
        this.inputRefs[`${category}-${newIndex}`].focus();
      }
    }
  );
  };

  handleKeyPress = (category, index, event) => {
    if (event.target.value !== '') {
      this.handleQuestionInputChange(category, index, event.target.value);
      this.handleAddQuestion(category);
    }
  };

  handleGenderChange = (event) => {
    const genderValue = event.target.value
    const checked = event.target.checked
    const { genderSelect, answers, correctMultipleOptions, categoriesFields } = this.state;
    const otherGender = genderValue === "female" ? "male" : "female";
  
    // Create a new copy of genderSelect
    let newGenderSelect = [...genderSelect];
  
    if (checked) {
      const otherGenderIndex = newGenderSelect.indexOf(otherGender);
      if (!newGenderSelect.includes(genderValue)) {
        newGenderSelect.push(genderValue);
      }
      if (otherGenderIndex > -1 && !newGenderSelect.includes("both")) {
        newGenderSelect.push("both");
      }
    } else {
      const index = newGenderSelect.indexOf(genderValue);
      if (index > -1) {
        newGenderSelect.splice(index, 1);
      }
      const bothIndex = newGenderSelect.indexOf("both");
      if (bothIndex > -1) {
        newGenderSelect.splice(bothIndex, 1);
      }
    }

    const lastGender = newGenderSelect[newGenderSelect.length - 1] || "";
    let newAnswers = { ...answers };
    let newCorrectMultipleOptions = { ...correctMultipleOptions };

    if (lastGender){
      categoriesFields.forEach(category => {
        if (newAnswers[category]) {
          newAnswers[category] = newAnswers[category].map(item => ({
            ...item,
            gender: lastGender 
          }));
        }
    
        if (newCorrectMultipleOptions[category]) {
          newCorrectMultipleOptions[category] = newCorrectMultipleOptions[category].map(item => ({
            ...item,
            gender: lastGender
          }));
        }
      });
    }

    this.setState({
      genderSelect: newGenderSelect,
      answers: newAnswers,
      correctMultipleOptions: newCorrectMultipleOptions,
    });
  }

  generateNow = () => {
    const { MCQsItems, possibleCases } = this.state;
    const totalRequired = parseInt(possibleCases, 10);
    const categories = [...new Set(MCQsItems.map((item) => item.category))];
    const itemsPerCategory = Math.floor(totalRequired / categories.length);
    const remainingItems = totalRequired % categories.length;
    const pickRandomFromCategory = (categoryItems, count) => {
      const shuffled = [...categoryItems].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, count);
    };
    let pickingMCQItems = categories.reduce((acc, category) => {
      const categoryItems = MCQsItems.filter(
        (item) => item.category === category
      );

      return [
        ...acc,
        ...pickRandomFromCategory(categoryItems, itemsPerCategory),
      ];
    }, []);
    if (remainingItems > 0) {
      const allRemainingItems = MCQsItems.filter(
        (item) => !pickingMCQItems.includes(item)
      );
      const randomRemainingItems = pickRandomFromCategory(
        allRemainingItems,
        remainingItems
      );
      pickingMCQItems = [...pickingMCQItems, ...randomRemainingItems];
    }
    pickingMCQItems = pickingMCQItems.sort(() => 0.5 - Math.random());
    this.setState(
      {
        genertaeNowMCQ: true,
        pickedMCQItems: pickingMCQItems,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  renderAgeInputs = () => (
    <>
      <div className="d-flex align-items-center mt-4 row case-aig justify-content-between">
        {["Days", "Weeks", "Months", "Years"].map((unit) => (
          <div
            className="my-4 col-lg-12 col-md-12 col-12 d-flex align-items-center justify-content-start"
            key={unit}
          >
            <b>{HEADING.ageRange}:</b>&nbsp;&nbsp;
            <Input
              style={{ width: "50px" }}
              placeholder={"min"}
              name={`minAgeIn${unit}`}
              value={
                this.state[`minAgeIn${unit}`] === 0
                  ? ""
                  : this.state[`minAgeIn${unit}`]
              }
              onChange={(e) => {
                this.setState({
                  [`minAgeIn${unit}`]:
                    e.target.value === "" ? 0 : Number(e.target.value),
                  pickedMCQItems: [],
                });
                this.resetData();
              }}
            />
            &nbsp;
            <big>-</big>&nbsp;
            <Input
              style={{ width: "50px" }}
              placeholder={"max"}
              name={`maxAgeIn${unit}`}
              value={
                this.state[`maxAgeIn${unit}`] === 0
                  ? ""
                  : this.state[`maxAgeIn${unit}`]
              }
              onChange={(e) => {
                this.setState({
                  [`maxAgeIn${unit}`]:
                    e.target.value === "" ? 0 : Number(e.target.value),
                  pickedMCQItems: [],
                });
                this.resetData();
              }}
            />
            <div className="mx-2" style={{ color: "black" }}>
              <b>in</b>
            </div>
            <Input
              value={unit.toUpperCase()}
              style={{ width: 100, textAlign: "center" }}
              readOnly
            />
          </div>
        ))}
      </div>
    </>
  );

  renderGenderInputs = () => (
    <>
      <div className="d-flex align-items-center mt-4 row case-aig justify-content-between">
      <div className="my-4 col-lg-12 col-md-12 col-12 d-flex align-items-center justify-content-start">
          <b>Gender:</b>&nbsp;&nbsp;
          <div style={{ display: "inline-block" }}>
                      <Form.Check
                        aria-label={`category 1`}
                        value={"male"}
                        checked={this.state.genderSelect.includes("male")}
                        className="mx-1"
                        onChange={this.handleGenderChange}
                      />
                    </div>
                    <div style={{ display: "inline-block" }}>
                      &nbsp;{"male"}&nbsp;&nbsp;
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <Form.Check
                        aria-label={`category 1`}
                        value={"female"}
                        checked={this.state.genderSelect.includes("female")}
                        className="mx-1"
                        onChange={this.handleGenderChange}
                      />
                    </div>
                    <div style={{ display: "inline-block" }}>
                      &nbsp;{"female"}&nbsp;&nbsp;
                    </div>
        </div>
      </div>
    </>
  )
  
  render() {
    const {
      story,
      question,
      answers,
      fields,
      isGenerated,
      tagfields,
      categoriesFields,
      questionTagsValue,
      questionCategoryValue,
      correctMultipleOptions,
      inputVisible,
      inputValue,
      selectedFeatures,
      genderSelect,
      MCQsItems,
      imported,
      topic,
      minAgeInDays,
      maxAgeInDays,
      minAgeInWeeks,
      maxAgeInWeeks,
      maxAgeInMonths,
      minAgeInMonths,
      minAgeInYears,
      maxAgeInYears,
      correctOptions,
      incorrectOptions,
      mcqsdata,
      totalMCQs,
      responseFromQues,
      questionInputs,
      genertaeNowMCQ,
      pickedMCQItems,
      tempStory,
      unchecking
    } = this.state;

    return (
      <main className="app">
        <Outlet />
        <CustomSideBar />
        <Header name="Automatic Item Generator v.3 (AIG-3)" />
        <section className="first">
          <Navigationbuttons showFile={this.showFile} />
          <div className="my-4">
            <Title level={3} className="mb-3">
              {HEADING.story}:
            </Title>
            {tempStory.map((el, index) => (
              <div key={index}>
                <StoryInput
                  value={el}
                  index={index}
                  onSaveRef={this.onStoryInputSaveRef}
                  onRemove={this.onStoryRemove}
                  onInputChange={this.onStoryInputChange}
                />
              </div>
            ))}
            <div className="d-flex">
              <Button
                className="d-flex align-items-center justify-content-center me-1"
                type="dashed"
                onClick={this.onStoryInputAdd}
                style={{ width: "30%" }}
                icon={<PlusOutlined />}
                id="btnAdd"
              >
                Add Story
              </Button>
              <Button
                className="d-flex align-items-center justify-content-center process ms-1"
                type="dashed"
                id="btnAdd"
                onClick={this.onStoryInputSubmit}
                // style={{ width: "20%" }}
              >
                Process Vignette
              </Button>
            </div>
          </div>
          {fields?.find((el) => el.key.toLowerCase() === "age") &&
            this.renderAgeInputs()}
          {fields?.find((el) => el.key.toLowerCase() === "gender") &&
           this.renderGenderInputs()} 
          {fields?.length > 0 && (
            <div className="my-4">
              <Title level={3} className="mb-3">
                {HEADING.variables}:
              </Title>
              {fields.map((field, index) => {
                if (field.key.toLowerCase() !== "age" && field.key.toLowerCase() !== "gender") {
                  return (
                    <Row
                      key={index}
                      align="middle"
                      className="mb-3 d-flex align-items-baseline"
                    >
                      <Col>
                        <b
                          className="pr-2"
                          style={{ textTransform: "capitalize" }}
                        >
                          {field.key}:
                        </b>
                      </Col>
                      <Col flex="1" className="d-flex mt-1 flex-wrap">
                        {field.value.map((tag, tagIndex) => (
                          <Chip
                            key={tagIndex}
                            index={tagIndex}
                            field={field.key}
                            item={tag}
                            onRemove={this.onChipRemove}
                          />
                        ))}
                        <ChipInput
                          visible={inputVisible[field.key]}
                          value={inputValue[field.key]}
                          field={field.key}
                          onSaveRef={this.onChipInputSaveRef}
                          onToggleVisb={this.onChipInputToggleVisb}
                          onInputChange={this.onChipInputChange}
                          onInputSubmit={this.onChipInputSubmit}
                        />
                      </Col>
                    </Row>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          )}
          {Object.keys(fields)?.length > 0 && (
            <div className="my-4" ref={this.sectionRef}>
              <Title level={3} className="mb-2">
                {HEADING.storyCategories}:
              </Title>
              <Row align="middle" className="mb-3">
                {/* <Col>
                <b className="pr-2">Topic:</b>
              </Col> */}
                <Col flex="1">
                  <div id="sectionToFocus">
                    <span className="inputcategories">
                      <Topic
                        onTopicChange={this.onChipStoryCategoriesInputChange}
                        topic={topic}
                        topicCheck={this.state.topicCheck}
                      />
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {Object.keys(fields)?.length > 0 && (
            <div className="my-4">
              <QuesionMode
                addCategory={this.addCategory}
                categoryNquestions={this.categoryNquestions}
                onAddQuestion={this.onAddQuestion}
                categoriesFields={categoriesFields}
                questions={question}
                uncheckedCategory={this.uncheckedCategory}
                unchecking={unchecking}
                updateUncheck={this.updateUncheck}
                onAddCategory={this.onAddCategory}
                questionCategoryValue={questionCategoryValue}
                importedCategories={this.state.importedCategories}
              />
            </div>
          )}
          <div>
            {Object.keys(fields)?.length > 0 &&
              Object.keys(responseFromQues).map((category, index) => {
                return (
                  <>
                    <Accordion
                      key={index}
                      // defaultActiveKey={index === 0 ? "0" : ""}
                      className="mb-3"
                      // style={{ borderRadius: "0.5rem" }}
                    >
                      <Accordion.Item
                        eventKey={`${index}`}
                        style={{ borderRadius: "0.5rem" }}
                      >
                        <Accordion.Header>
                          <h3
                            className="text-center mb-0"
                            style={{
                              fontSize: "19px",
                              fontWeight: "700",
                              color: "black",
                            }}
                          >
                            {category}
                          </h3>
                        </Accordion.Header>
                        <Accordion.Body>
                        <Title level={3} className="mb-3" id="title">
                          {HEADING.question}:
                        </Title>
                        {question[category].map((item, index) => (
                            <div style={{ display: 'flex' }} key={index}>
                              <Input
                                className="mb-2 mt-2"
                                value={item.question}
                                ref={(ref) => (this.inputRefs[`${category}-${index}`] = ref)}
                                onChange={(e) =>
                                  this.handleQuestionInputChange(category, index, e.target.value)
                                }
                                onPressEnter={(e) => this.handleKeyPress(category, index, e)}
                                placeholder="Enter Question"
                              />
                              <CloseCircleFilled
                                className="dynamic-delete-button"
                                onClick={() => this.handleRemoveQuestion(category, index)}
                              />
                            </div>
                          ))}
                          <Button
                            className="d-flex align-items-center justify-content-center"
                            type="dashed"
                            id="btnAdd"
                            onClick={() => this.handleAddQuestion(category)}
                            style={{ width: "40%" }}
                            icon={<PlusOutlined />}
                          >
                            Add Question
                          </Button>
                          {Object.keys(question)?.length > 0 &&
                            question[0] !== "" && (
                              <div className="my-4">
                                <form>
                                  <div className="my-4">
                                    <Title
                                      level={3}
                                      className="mb-3"
                                      id="title"
                                    >
                                      {`${HEADING.Correct1}`}:
                                    </Title>
                                    {correctMultipleOptions?.[category]?.map(
                                      (el, index) => {
                                        return (
                                          <CorrectOptionSection
                                            value={el}
                                            key={index}
                                            category={category}
                                            index={index}
                                            categoriesFields={categoriesFields}
                                            resetData={this.resetData}
                                            onSaveRef={
                                              this
                                                .onCorrectMultipleOptionsInputSaveRef
                                            }
                                            onRemove={
                                              this
                                                .onCorrectMultipleOptionsRemove
                                            }
                                            onInputChange={
                                              this
                                                .onCorrectMultipleOptionsInputChange
                                            }
                                            onInputSubmit={
                                              this
                                                .onCorrectMultipleOptionsInputAdd
                                            }
                                            onLoad={
                                              this.onCorrectMultipleOptionsLoad
                                            }
                                            selectedFeatures={selectedFeatures}
                                            genderSelect={genderSelect}
                                            imported={imported}
                                          />
                                        );
                                      }
                                    )}
                                    <Button
                                      className="d-flex align-items-center justify-content-center"
                                      type="dashed"
                                      id="btnAdd"
                                      onClick={() => this.onCorrectMultipleOptionsInputAdd(correctMultipleOptions[category]?.length - 1, category, true)}
                                      style={{ width: "40%" }}
                                      icon={<PlusOutlined />}
                                    >
                                      Add option
                                    </Button>
                                  </div>
                                </form>
                              </div>
                            )}
                          {Object.keys(question)?.length > 0 &&
                            question[0] !== "" && (
                              <div className="my-4">
                                <form>
                                  <div className="my-4">
                                    <Title
                                      level={3}
                                      className="mb-3"
                                      id="title"
                                    >
                                      {`${HEADING.optionSection}`}:
                                    </Title>
                                    {answers?.[category]?.map((el, index) => {
                                      return (
                                        <OptionSection
                                          value={el}
                                          category={category}
                                          index={index}
                                          imported={imported}
                                          categoriesFields={categoriesFields}
                                          resetData={this.resetData}
                                          onSaveRef={this.onAnswerInputSaveRef}
                                          onRemove={this.onAnswerRemove}
                                          onInputChange={
                                            this.onAnswersInputChange
                                          }
                                          onInputSubmit={this.onAnswersInputAdd}
                                          onLoad={this.onAnswersLoad}
                                          selectedFeatures={selectedFeatures}
                                          genderSelect={genderSelect}
                                        />
                                      );
                                    })}
                                    <Button
                                      className="d-flex align-items-center justify-content-center"
                                      type="dashed"
                                      id="btnAdd"
                                      onClick={() => this.onAnswersInputAdd(answers[category]?.length - 1, category, true)}
                                      style={{ width: "40%" }}
                                      icon={<PlusOutlined />}
                                    >
                                      Add option
                                    </Button>
                                  </div>
                                </form>
                              </div>
                            )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                );
              })}
          </div>
        </section>
        {questionCategoryValue.length > 0  && (
          <div className="d-lg-flex d-md-flex" style={{justifyContent:"space-around"}}>
            <Col className="mt-lg-0 mt-md-0 mt-2">
              <Button
                className="ms-lg-2 ms-md-2 d-flex align-items-center"
                size="large"
                id="btnAdd"
                icon={<SafetyOutlined />}
                onClick={this.generateAndRender}
              >
                <>{HEADING.previewButton}(Questions)</>
              </Button>
            </Col>
            <Col className="mt-lg-0 mt-md-0 mt-2 ml-2">
              <Button
                size="large"
                id="btnAdd"
                icon={<DownloadOutlined />}
                onClick={() => this.downloadFile(this.state)}
                className="d-flex align-items-center justify-content-center ms-lg-0 ms-md-2 mt-lg-0 mt-md-0 mt-2 download"
                style={{ whiteSpace: "nowrap" }}
              >
                Save Question Template
            </Button>
            </Col>
          </div>
        )}
        {isGenerated && (
          <>
            <div className="d-lg-flex d-md-flex d-sm-block mt-4">
              <Col>
                <Title level={3} style={{ marginTop: "5px" }}>
                  Enter no. of MCQs:{" "}
                </Title>
              </Col>
              <Col className="ms-1">
                <Input
                  style={{
                    height: "40px",
                    width: "100px",
                    borderRadius: "0px",
                  }}
                  id="inputMcq"
                  placeholder={"1 - " + this.state.totalMCQs}
                  type="number"
                  min="1"
                  max={this.state.totalMCQs}
                  name={this.state.possibleCases}
                  value={this.state.possibleCases}
                  onChange={(e) => {
                    if (e.target.value <= this.state.totalMCQs) {
                      this.setState({
                        possibleCases: e.target.value,
                      });
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") this.generateNow();
                  }}
                />
              </Col>
              <Button
                className="ms-lg-2 ms-md-2 d-flex align-items-center"
                size="large"
                id="btnAdd"
                icon={<SafetyOutlined />}
                onClick={this.generateNow}
              >
                Generate Now
              </Button>
            </div>
          </>
        )}
        {genertaeNowMCQ && pickedMCQItems?.length > 0 && (
          <section class="mt-3">
            <Title level={3} className="mb-3">
              Quesions Preview ({pickedMCQItems?.length} Questions)
            </Title>
            {pickedMCQItems.map((item, itemIndex) => {
              const allOptions = [...item.incorrect_option, item.correct_option];
              allOptions.sort(() => Math.random() - 0.5);
              return (
                <ul className="ul px-0" key={itemIndex}>
                  <TransitionGroup
                    component={null}
                    appear={true}
                    in={true}
                    mountOnEnter={true}
                    unmountOnExit={true}
                  >
                    <CSSTransition timeout={500} classNames="example">
                      <li>
                        <Title level={4} className="mb-4">
                          <span dangerouslySetInnerHTML={{ __html: `${itemIndex + 1}. ` }} />
                          <span dangerouslySetInnerHTML={{ __html: item.mcq }} />
                        </Title>
                        <Title level={4} className="mb-4">
                          {Object.values(item.question)}
                        </Title>
                        <ul className="ul-2">
                          {allOptions.map((option, optionIndex) => (
                            <li key={optionIndex} style={{ display: "flex" }}>
                              <Title level={4}>
                                {String.fromCharCode("a".charCodeAt(0) + optionIndex)}.
                              </Title>
                              &nbsp; {option}
                              {option === item.correct_option && " *"}
                            </li>
                          ))}
                        </ul>
                      </li>
                    </CSSTransition>
                  </TransitionGroup>
                </ul>
              );
            })}
            <div
              className="d-lg-flex d-md-flex d-sm-block"
              style={{ justifyContent: "space-around" }}
            >
              <Button
                size="large"
                id="btnAdd"
                icon={<DownloadOutlined />}
                onClick={this.generateLatexAndDownload}
                className="d-flex align-items-center justify-content-center download"
                style={{ whiteSpace: "nowrap" }}
              >
                {HEADING.generateButton} ({this.state.possibleCases} Questions)
              </Button>

              <Button
                size="large"
                id="btnAdd"
                icon={<DownloadOutlined />}
                onClick={() => this.sendData(this.state.apiData)}
                className="d-flex align-items-center justify-content-center ms-lg-0 ms-md-2 mt-lg-0 mt-md-0 mt-2 download"
                style={{ whiteSpace: "nowrap" }}
              >
                Save MCQs to Question Bank
              </Button>
            </div>
          </section>
        )}
      </main>
    );
  }
}

export default NavigateWrapper;
